import QuickLinks from "../Components/QuickLinks"


export default function Home(){
    
    return(
        <>
        <QuickLinks />
        </>
    )
}