
export default function NoFormsFound(){

    return(
        <>
            <div className="d-flex justify-content-center align-items-center" style={{height:'50vh'}}>
                <h4>No Forms Found</h4>
            </div>
            
        </>
    )
}