import { useState } from "react";
import { Button , Form  , Modal} from "react-bootstrap";
import Swal from "sweetalert2";

export default function AddNewForm({setForms, refreshEffect}){
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [link , setLink] = useState('');
    const [showAddNewForm , setShowAddNewForm] = useState(false)

    const openEdit = () => {
        
        setShowAddNewForm(true);
    }
    const addNewForm = (e) =>{
        e.preventDefault();
        fetch(`${process.env.REACT_APP_API_URL}/api/forms/newForm`,{
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                name: name,
                description:description,
                link:link
            })
        })
        .then(data => {
            if(data.status === 201){
                Swal.fire({
                    title: "Success",
                    icon: 'success',
                    text: 'Form added succesfully'
                })
                refreshEffect();
                closeEdit();
            } else {
                Swal.fire({
                    title: "Error!!",
                    icon: 'error',
                    text: 'Please try again'
                })
                closeEdit();
            }

        })
    }
    const closeEdit = () => {
        setName('');
        setDescription('');
        setLink('');
        setShowAddNewForm(false);
    }
    return(
        <>
        <Button className="button-bg" size="sm" onClick={() => openEdit()}>Add New Form</Button>

        <Modal show={showAddNewForm} onHide={closeEdit}>
            <Form onSubmit={(e)=> addNewForm(e)}>
                <Modal.Header>
                    <Modal.Title>Add New Form</Modal.Title>
                    <Button onClick={() => closeEdit()} className="modal-close bg-contrast">X</Button>
                </Modal.Header>
                <Modal.Body>    
                    <Form.Group controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} required/>
                    </Form.Group>

                    <Form.Group controlId="description">
                        <Form.Label>Form Description</Form.Label>
                        <Form.Control type="text" value={description} onChange={(e) => setDescription(e.target.value)} required/>
                    </Form.Group>

                    <Form.Group controlId="link">
                        <Form.Label>Form Link</Form.Label>
                        <Form.Control type="text" value={link} onChange={(e) => setLink(e.target.value)} required/>
                    </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeEdit}>Close</Button>
                    <Button variant="success" type="submit">Post</Button>
                </Modal.Footer>
            </Form>
        </Modal>
        </>
    )
}